import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './PaymentDetail.module.css';

export const PaymentResponseSuccess = () => {
   // const search = useLocation().search;
   // const msg = new URLSearchParams(search).get('msg');

   return (
      <div className="row ">
         <div className="mt-5 align-items-center">
            <div className={`d-flex justify-content-center `}>
               <div className="col-md-6 ">
                  <div className={classes.payment}>
                     <div className={classes.payment_header}>
                        <div className={classes.check}>
                           <FeatherIcon
                              icon="check"
                              size={30}
                              className={classes.checkicon}
                           />
                        </div>
                     </div>
                     <div className={classes.content}>
                        <h1>Payment Success</h1>
                        <div>
                           <p>
                              {
                                 'Payment Successfully updated. Use the below button to navigate to the dashboard. '
                              }
                           </p>
                        </div>
                        <Link className={classes.goBackLink} to="/">
                           Go back
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default PaymentResponseSuccess;
