import classes from './NotFound.module.css';
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const NotFound = () => {
   const navigate = useNavigate();
   const isLoggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });

   useEffect(() => {
      if (!isLoggedIn) {
         navigate('/');
      }
   }, []);

   return (
      <>
         <div className={classes.notFound}>
            <h1>Still not available. Please try the other routes.</h1>
         </div>
      </>
   );
};

export default NotFound;
