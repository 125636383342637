import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classes from './Sidebar.module.css';
import React from 'react';
import {
   sidebarBottomConstants,
   sidebarTopConstants,
} from '../../constants/sidebarConstants';
import { systemActions } from '../../state';

const Sidebar = (props) => {
   const location = useLocation();
   const dispatch = useDispatch();
   const googleId = useSelector((state) => {
      return state.driveId;
   });
   const isSidebarOpen = useSelector((status) => {
      return status.isSidebarOn;
   });

   const sideBarOpenHandler = () => {
      if (isSidebarOpen) {
         dispatch(
            systemActions.menubarOpen({
               isSidebarOn: false,
            }),
         );
      } else if (!isSidebarOpen) {
         dispatch(
            systemActions.menubarOpen({
               isSidebarOn: true,
            }),
         );
      }
   };
   const sideBarClass = props.sideBarStatus
      ? `${classes.sideBar} ${classes.open}`
      : classes.sideBar;

   const labelClass = props.sideBarStatus
      ? classes.menuItemLabel
      : `${classes.menuItemLabel} ${classes.hidden}`;

   const brandClass = props.sideBarStatus
      ? `${classes.brand}`
      : `${classes.brand} ${classes.brandHide}`;

   const handleMenu = (menuItem) => {
      dispatch(
         systemActions.menubarActive({
            sidebarActive: menuItem,
         }),
      );
   };

   const renderSidebarItems = (array) => {
      let menuItems = [];
      array.forEach((item) => {
         if (item.label === 'My Docs') {
            if (googleId) {
               let newItem = (
                  <Link
                     to={item.path}
                     className={classes.menuItemLink}
                     key={item.label}
                  >
                     <div
                        className={classes.menuItem}
                        onClick={() => {
                           handleMenu(item.tag);
                        }}
                     >
                        {location.pathname === item.path && (
                           <div className={classes.activeTick}></div>
                        )}
                        <div className={classes.menuItemWrapper}>
                           <FeatherIcon
                              className={classes.icons}
                              icon={item.icon}
                              size={25}
                           />
                           <div className={labelClass}>{item.label}</div>
                        </div>
                     </div>
                  </Link>
               );
               menuItems.push(newItem);
            }
         } else {
            let newItem = (
               <Link
                  to={item.path}
                  className={classes.menuItemLink}
                  key={item.label}
               >
                  <div
                     className={classes.menuItem}
                     onClick={() => {
                        handleMenu(item.tag);
                     }}
                  >
                     {location.pathname === item.path && (
                        <div className={classes.activeTick}></div>
                     )}
                     <div className={classes.menuItemWrapper}>
                        <FeatherIcon
                           className={classes.icons}
                           icon={item.icon}
                           size={25}
                        />
                        <div className={labelClass}>{item.label}</div>
                     </div>
                  </div>
               </Link>
            );
            menuItems.push(newItem);
         }
      });
      return menuItems;
   };

   return (
      <div className={sideBarClass}>
         <div className={classes.menuButton}>
            <div>
               <FeatherIcon
                  className={classes.icons}
                  icon="grid"
                  size={30}
                  // @ts-ignore
                  onClick={sideBarOpenHandler}
               />
            </div>
            <div className={brandClass}>Silkroad</div>
         </div>
         <div className={classes.menuItems}>
            <div>
               {renderSidebarItems(sidebarTopConstants)}
               <div className={classes.horizontalRule}></div>
               {renderSidebarItems(sidebarBottomConstants)}
            </div>

            {/* <div className={classes.menuItemLink} onClick={logoutHandler}>
               <div className={classes.menuItem}>
                  <div className={classes.menuItemWrapper}>
                     <FeatherIcon
                        className={classes.icons}
                        icon="log-out"
                        size={25}
                     />
                     <div className={labelClass}>Logout</div>
                  </div>
               </div>
            </div> */}
         </div>
      </div>
   );
};

export default Sidebar;
