/* eslint-disable array-callback-return */
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SpinnerDiamond } from 'spinners-react';
import Typewriter from 'typewriter-effect';
import TopNav from '../../components/TopNav/TopNav';
import { systemActions } from '../../state';
import BackIcon from './../../assets/icons/chevrons-left.svg';
import folderIcon from './../../assets/icons/folder-icon.svg';
import fileIcon from './../../assets/icons/pdf-icon.svg';
import classes from './Docs.module.css';

const Docs = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const isLoggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });

   useEffect(() => {
      if (!isLoggedIn) {
         navigate('/');
      }
   }, []);
   const updatedFileStructure = useSelector((state) => {
      if (state.updatedFileStructure.length > 1) {
         return state.updatedFileStructure[
            state.updatedFileStructure.length - 1
         ];
      } else {
         return state.updatedFileStructure;
      }
   });

   const driveLoading = useSelector((state) => {
      return state.driveLoading;
   });

   const files = useSelector((state) => {
      if (state.files.length > 1) {
         return state.files[state.files.length - 1];
      } else {
         return state.files;
      }
   });

   const breadCrumb = useSelector((state) => {
      return state.breadcrumb;
   });

   const handleFolderClick = (folder, option) => {
      if (folder.subFolder) {
         dispatch(
            systemActions.updatedPath({
               updatedFileStructure: folder.subFolder,
               option: 'forward',
            }),
         );
         dispatch(
            systemActions.updateFiles({
               files: folder.files,
            }),
         );
         dispatch(
            systemActions.updateBreadCrumb({
               folderName: folder.folderName,
               option,
            }),
         );
      }
   };

   const openInNewTab = (url) => {
      window.open(url, '_blank', 'noopener,noreferrer');
   };

   const generateFiles = (array) => {
      let files = [];

      array.map((file) => {
         if (!Array.isArray(file)) {
            let newFile = (
               <div
                  className={classes.folderComponent}
                  onDoubleClick={() => {
                     openInNewTab(file.webLink);
                  }}
               >
                  <img
                     src={fileIcon}
                     alt="folder icon"
                     className={classes.folderIcon}
                  />
                  <p className={classes.folderDescription}>{file.name}</p>
               </div>
            );
            files.push(newFile);
         }
      });

      return files;
   };

   const generateFolders = (array) => {
      let folders = [];

      array.map((folder) => {
         let newFolder = (
            <div
               className={classes.folderComponent}
               onDoubleClick={() => {
                  handleFolderClick(folder, 'push');
               }}
            >
               <img
                  src={folderIcon}
                  alt="folder icon"
                  className={classes.folderIcon}
               />
               <p className={classes.folderDescription}>{folder.folderName}</p>
            </div>
         );
         folders.push(newFolder);
      });
      return [...folders];
   };

   const generateBreadCrumb = (array) => {
      let breadCrumb = [];
      array.map((path) => {
         let newBreadCrumb = (
            <div className={classes.newBreadCrumb}>
               <p>{path}</p>
            </div>
         );
         if (breadCrumb.length > 0) {
            breadCrumb.push('>');
         }
         breadCrumb.push(newBreadCrumb);
      });

      return breadCrumb;
   };

   const backButtonHandler = () => {
      if (breadCrumb.length >= 1) {
         dispatch(
            systemActions.backButtonPath({
               updatedFileStructure: updatedFileStructure,
            }),
         );
      }
   };

   let toggle;

   return (
      <div className={classes.docPage}>
         <TopNav />
         <div>
            <div className={classes.folderContainer}>
               <h4>Folder structure</h4>
               <div className={classes.fileStructurePreview}>
                  {breadCrumb.length >= 1 && (
                     <button
                        className={classes.backButton}
                        onClick={backButtonHandler}
                     >
                        <img
                           src={BackIcon}
                           alt="back button"
                           className={` ${toggle}`}
                        />
                     </button>
                  )}
                  {breadCrumb && generateBreadCrumb(breadCrumb)}
                  <div className={classes.folderComponentList}>
                     {updatedFileStructure &&
                        generateFolders(updatedFileStructure)}
                     {files && generateFiles(files)}
                  </div>
               </div>
            </div>
         </div>

         {driveLoading && (
            <div className={classes.loadingComponent}>
               <div className={classes.loader}>
                  <SpinnerDiamond
                     className={classes.loaderSpin}
                     size={90}
                     thickness={100}
                     speed={71}
                     color="rgba(0,0,0,1)"
                     secondaryColor="rgba(0, 0, 0, 0.3)"
                  />

                  <div className={classes.loadingDescription}>
                     <Typewriter
                        options={{
                           strings: [
                              'Reaching out to Google Drive...',
                              'Please wait....',
                              'You are almost there ...',
                              'Just a couple of seconds ...',
                           ],
                           autoStart: true,
                           loop: true,
                           textAlign: 'center',
                        }}
                     />
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};

export default Docs;
