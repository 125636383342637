export const menuItem = {
   home: 'home',
   docs: 'docs',
   reports: 'reports',
   payments: 'payments',
   invoices: 'invoices',
   blogs: 'blogs',
   faq: 'faq',
   otherServices: 'otherServices',
   contactUs: 'contactUs',
};
export const initialState = {
   sidebarActive: menuItem.home,
   // drive status
   driveLoading: false,
   driveId: '',
   data: {},
   files: [],
   breadcrumb: [],
   updatedFileStructure: [],
   beforeFileStrcuture: [],

   //sidebar
   isSidebarOn: false,

   //logged in user
   isLoggedIn: false,

   //client Id
   clients: [],
   clientId: '',
   selectedCompany: '',

   //Invoice Fetch
   invoices: [],
   invoiceLoading: false,

   // Payments
   payments: [],
   paymentsLoading: false,

   //total outstanding
   outstanding: 0,
   currency: '',
};

//home, docs, reports,payments,invoices,blogs,faq,otherServices,contactUs
