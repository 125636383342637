import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const InvoiceFetchThunk = createAsyncThunk(
   'AppState/InvoiceFetchThunk',
   async (args, { rejectWithValue }) => {
      try {
         const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/xero/get-invoices`,
            {
               contactId: args.contactId,
            },
         );

         return data;
      } catch (error) {
         rejectWithValue(error.response.data);
      }
   },
);
