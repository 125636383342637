import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TopNav from '../../components/TopNav/TopNav';
import classes from './ContactList.module.css';

export const ContactList = (props) => {
   const navigate = useNavigate();
   return (
      <>
         <div className={classes.home}>
            <div className={classes.docPage}>
               <TopNav />
               <div>
                  <div className={classes.folderContainer}>
                     <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                 <Link
                                    to="/home"
                                    className={classes.breadcrumbText}
                                 >
                                    {'Home'}
                                 </Link>
                              </li>

                              <li
                                 className="breadcrumb-item active"
                                 aria-current="page"
                              >
                                 Contact List
                              </li>
                           </ol>
                        </nav>
                        <button
                           className="btn btn-dark my-3"
                           onClick={() => {
                              navigate(-1);
                           }}
                        >
                           Go Back
                        </button>{' '}
                     </div>
                     <div className={classes.fileStructurePreview}>
                        <div>
                           <div className="row">
                              <div className="col-xl-3 col-sm-6">
                                 <div className={`${classes.cardPreview} `}>
                                    <div className="card-body">
                                       <div className="d-flex align-items-center">
                                          <div>
                                             <FeatherIcon
                                                className={`${classes.avatarMd} rounded-circle img-thumbnail`}
                                                icon="user"
                                                size={20}
                                             />
                                          </div>
                                          <div className="flex-1 ms-3">
                                             <h5
                                                className={classes.contactName}
                                             >
                                                <div className="text-dark">
                                                   Phyllis Gatlin
                                                </div>
                                             </h5>
                                             <span className={classes.title}>
                                                General Manager
                                             </span>
                                          </div>
                                       </div>
                                       <div className="mt-3 pt-1">
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="phone"
                                                size={20}
                                             />
                                             {' 070 2860 5375'}
                                          </p>
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="mail"
                                                size={20}
                                             />
                                             {' PhyllisGatlin@spy.com '}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-3 col-sm-6">
                                 <div className={`${classes.cardPreview} `}>
                                    <div className="card-body">
                                       <div className="d-flex align-items-center">
                                          <div>
                                             <FeatherIcon
                                                className={`${classes.avatarMd} rounded-circle img-thumbnail`}
                                                icon="user"
                                                size={20}
                                             />
                                          </div>
                                          <div className="flex-1 ms-3">
                                             <h5
                                                className={classes.contactName}
                                             >
                                                <div className="text-dark">
                                                   Phyllis Gatlin
                                                </div>
                                             </h5>
                                             <span className={classes.title}>
                                                General Manager
                                             </span>
                                          </div>
                                       </div>
                                       <div className="mt-3 pt-1">
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="phone"
                                                size={20}
                                             />
                                             {' 070 2860 5375'}
                                          </p>
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="mail"
                                                size={20}
                                             />
                                             {' PhyllisGatlin@spy.com '}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-3 col-sm-6">
                                 <div className={`${classes.cardPreview} `}>
                                    <div className="card-body">
                                       <div className="d-flex align-items-center">
                                          <div>
                                             <FeatherIcon
                                                className={`${classes.avatarMd} rounded-circle img-thumbnail`}
                                                icon="user"
                                                size={20}
                                             />
                                          </div>
                                          <div className="flex-1 ms-3">
                                             <h5
                                                className={classes.contactName}
                                             >
                                                <div className="text-dark">
                                                   Phyllis Gatlin
                                                </div>
                                             </h5>
                                             <span className={classes.title}>
                                                General Manager
                                             </span>
                                          </div>
                                       </div>
                                       <div className="mt-3 pt-1">
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="phone"
                                                size={20}
                                             />
                                             {' 070 2860 5375'}
                                          </p>
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="mail"
                                                size={20}
                                             />
                                             {' PhyllisGatlin@spy.com '}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-3 col-sm-6">
                                 <div className={`${classes.cardPreview} `}>
                                    <div className="card-body">
                                       <div className="d-flex align-items-center">
                                          <div>
                                             <FeatherIcon
                                                className={`${classes.avatarMd} rounded-circle img-thumbnail`}
                                                icon="user"
                                                size={20}
                                             />
                                          </div>
                                          <div className="flex-1 ms-3">
                                             <h5
                                                className={classes.contactName}
                                             >
                                                <div className="text-dark">
                                                   Phyllis Gatlin
                                                </div>
                                             </h5>
                                             <span className={classes.title}>
                                                General Manager
                                             </span>
                                          </div>
                                       </div>
                                       <div className="mt-3 pt-1">
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="phone"
                                                size={20}
                                             />
                                             {' 070 2860 5375'}
                                          </p>
                                          <p className={classes.contactDetail}>
                                             <FeatherIcon
                                                icon="mail"
                                                size={20}
                                             />
                                             {' PhyllisGatlin@spy.com '}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* {loading && (
            <div className={classes.loadingComponent}>
               <div className={classes.loader}>
                  <SpinnerDiamond
                     className={classes.loaderSpin}
                     size={90}
                     thickness={100}
                     speed={71}
                     color="rgba(0,0,0,1)"
                     secondaryColor="rgba(0, 0, 0, 0.3)"
                  />

                  <div className={classes.loadingDescription}>
                     <Typewriter
                        options={{
                           strings: ['Loading ....', 'Please wait...'],
                           autoStart: true,
                           loop: true,
                           textAlign: 'center',
                        }}
                     />
                  </div>
               </div>
            </div>
         )} */}
      </>
   );
};
export default ContactList;
