import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { systemActions } from '../../state';
import { InvoiceFetchThunk } from '../../state/thunks/InvoicesFetchThunk';
import './Navbar.css';
import classes from './TopNav.module.css';

const TopNav = (props) => {
   const location = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const isLoggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });

   const companyActive = useSelector((state) => {
      return state.selectedCompany;
   });

   const companies = useSelector((state) => {
      return state.clients;
   });

   const companyClickHandler = (clientId, selectedCompany) => {
      dispatch(InvoiceFetchThunk({ contactId: clientId }));
      dispatch(
         systemActions.selectCompany({
            clientId,
            selectedCompany,
         }),
      );
   };

   useEffect(() => {
      if (!isLoggedIn) {
         navigate('/');
      }
   }, []);

   // const dropdown = () => {

   // };

   const logoutHandler = async () => {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`);
      dispatch(
         systemActions.loggedIn({
            status: false,
         }),
      );
      navigate('/');
   };

   const hour = new Date().getHours();
   const welcomeTypes = [
      'Good morning...!',
      'Good afternoon....!',
      'Good evening....!',
   ];

   let welcomeText;

   if (hour < 12) welcomeText = welcomeTypes[0];
   else if (hour < 18) welcomeText = welcomeTypes[1];
   else welcomeText = welcomeTypes[2];

   const companyTiles = (array) => {
      let companyList = [];

      array.forEach((company) => {
         if (company.companyName === companyActive) {
            let companyTile = (
               <div
                  className={`dropdown-item company-nav-item active`}
                  key={company.contactId}
                  onClick={() => {
                     companyClickHandler(
                        company.contactId,
                        company.companyName,
                     );
                  }}
               >
                  {company.companyName}
               </div>
            );
            companyList.push(companyTile);
         } else {
            let companyTile = (
               <div
                  className={`dropdown-item company-nav-item`}
                  key={company.contactId}
                  onClick={() => {
                     companyClickHandler(
                        company.contactId,
                        company.companyName,
                     );
                  }}
               >
                  {company.companyName}
               </div>
            );
            companyList.push(companyTile);
         }
      });

      return companyList;
   };

   return (
      <div className={classes.topWrapper}>
         <div className={classes.horizontalLine}>
            <div className={classes.brandWrapper}>
               <h1 className={classes.brandName}>
                  <span>Silkroad</span>
                  &nbsp;Client Dashboard
               </h1>
               <div className={classes.notificationContent}>
                  {/* <div className={classes.notificationWrapper}>
                     <FeatherIcon icon="bell" size={20} />
                     <div className={classes.notificationTick}></div>
                  </div> */}
                  <div className={classes.logout} onClick={logoutHandler}>
                     <FeatherIcon
                        className={classes.logoutIcon}
                        icon="log-out"
                        size={24}
                     />
                     <div className=""></div>
                  </div>
               </div>
            </div>
            <div className={classes.headerWrapper}>
               <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                     <h1 className={classes.dashboardHeading}>
                        <span>
                           {' '}
                           Hello <b>David,</b>
                        </span>
                     </h1>
                     <h4 className={classes.dashboardGreeting}>
                        {welcomeText}
                     </h4>
                  </div>
                  {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                     <div className="dropdown show companydrop">
                        <div
                           className="btn btn-secondary dropdown-toggle companydrop-btn"
                           role="button"
                           id="dropdownMenuLink"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           {companyActive}
                        </div>

                        <div
                           className="dropdown-menu dropdown-company"
                           aria-labelledby="dropdownMenuLink"
                        >
                           {companyTiles(companies)}
                        </div>
                     </div>
                  </div> */}
                  {location.pathname === '/home' ||
                  location.pathname === '/invoices' ? (
                     <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="dropdown show companydrop">
                           <div
                              className="btn btn-secondary dropdown-toggle companydrop-btn"
                              role="button"
                              id="dropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                           >
                              {companyActive}
                           </div>

                           <div
                              className="dropdown-menu dropdown-company"
                              aria-labelledby="dropdownMenuLink"
                           >
                              {companyTiles(companies)}
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div className="col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center align-items-center">
                        <p className={`${classes.companyName}`}>
                           {companyActive}
                        </p>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default TopNav;
