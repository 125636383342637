import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import TopNav from '../../components/TopNav/TopNav';
import Typewriter from 'typewriter-effect';
import logo from './../../assets/images/logo.jpg';
import classes from './Invoices.module.css';
import { SpinnerDiamond } from 'spinners-react';
export const InvoiceDetail = (props) => {
   // const dispatch = useDispatch();
   const { id } = useParams();
   const [invoiceDetails, setInvoiceDetails] = useState({});
   const navigate = useNavigate();
   const isLoggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });

   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (isLoggedIn) {
         getInvoiceDetails(id);
      } else if (!isLoggedIn) {
         navigate('/');
      }
   }, []);

   const getInvoiceDetails = async (id) => {
      setLoading(true);
      const response = await axios.post(
         `${process.env.REACT_APP_BACKEND_URL}/api/xero/get-invoice`,
         {
            invoiceId: id,
         },
      );
      const Invoice = response.data.invoice.Invoices[0];
      let address = `${Invoice.Contact.Addresses[0].City}`;

      let invoice = {
         company: Invoice.Contact.Name,
         name: `${Invoice.Contact.FirstName} ${Invoice.Contact.LastName}`,
         address: address,
         region: Invoice.Contact.Addresses[0].Region,
         country: Invoice.Contact.Addresses[0].Country,
         postalCode: Invoice.Contact.Addresses[0].PostalCode,
         invoiceDate: Invoice.DateString.slice(0, 10),
         invoiceNumber: Invoice.InvoiceNumber,
         reference: Invoice.Reference,
         lineItems: Invoice.LineItems,
         subTotal: Invoice.SubTotal,
         totalTax: Invoice.TotalTax,
         Total: Invoice.Total,
         currency: Invoice.CurrencyCode,
         paid: Invoice.AmountPaid,
         due: Invoice.AmountDue,
         status: Invoice.Status,
      };

      setLoading(false);

      setInvoiceDetails(invoice);
   };

   const renderLineItems = (array) => {
      let lineItems = [];
      array.forEach((item) => {
         let newItem = (
            <tr>
               <td>{item.Description}</td>
               <td className="text-right">{item.Quantity}</td>
               <td className="text-right">{item.UnitAmount}</td>
               {item.TaxAmount === 0 ? (
                  <td>Tax Exempt</td>
               ) : (
                  <td>{item.TaxAmount}</td>
               )}
               <td className="text-right">{item.LineAmount}</td>
            </tr>
         );
         lineItems.push(newItem);
      });
      return lineItems;
   };

   return (
      <>
         <div className={classes.home}>
            <div className={classes.docPage}>
               <TopNav />
               <div>
                  <div className={classes.folderContainer}>
                     <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                 <Link
                                    to="/home"
                                    className={classes.breadcrumbText}
                                 >
                                    {'Home'}
                                 </Link>
                              </li>
                              {props.isInvoices && (
                                 <li className="breadcrumb-item">
                                    <Link
                                       to="/invoices"
                                       className={classes.breadcrumbText}
                                    >
                                       {'Invoices'}
                                    </Link>
                                 </li>
                              )}
                              {props.isInvoices === false && (
                                 <li className="breadcrumb-item">
                                    <Link
                                       to="/outstandingPayments"
                                       className={classes.breadcrumbText}
                                    >
                                       {'Outstanding Payments'}
                                    </Link>
                                 </li>
                              )}

                              <li
                                 className="breadcrumb-item active"
                                 aria-current="page"
                              >
                                 Invoice Detail
                              </li>
                           </ol>
                        </nav>
                        <button
                           className="btn btn-dark my-3"
                           onClick={() => {
                              navigate(-1);
                           }}
                        >
                           Go Back
                        </button>{' '}
                     </div>

                     <div className="container">
                        <div className="col-md-10 offset-md-1">
                           <div className="row">
                              <div className="col-md-1 offset-md-11">
                                 <img
                                    src={logo}
                                    alt="company logo"
                                    className={`${classes.invoiceLogo} float-right`}
                                 />
                              </div>
                           </div>
                           <div className="row">
                              <h3 className={classes.invoiceHeading}>
                                 INVOICE{' '}
                                 {invoiceDetails.status === 'PAID' && (
                                    <div
                                       className={`${classes.bedgeInvoice} badge bg-success`}
                                    >
                                       Paid
                                    </div>
                                 )}
                                 {invoiceDetails.status !== 'PAID' && (
                                    <div
                                       className={`${classes.bedgeInvoice} badge bg-danger`}
                                    >
                                       Pending
                                    </div>
                                 )}
                              </h3>
                              <div className="col-md-4">
                                 <div>
                                    <p className={classes.invoiceAddress}>
                                       {invoiceDetails.company}
                                    </p>
                                    <p className={classes.invoiceAddress}>
                                       {invoiceDetails.name}
                                    </p>
                                    <p className={classes.invoiceAddress}>
                                       {invoiceDetails.address}
                                    </p>
                                    <p className={classes.invoiceAddress}>
                                       {invoiceDetails.region}
                                    </p>
                                    <p className={classes.invoiceAddress}>
                                       {invoiceDetails.country}
                                    </p>
                                    <p className={classes.invoiceAddress}>
                                       {invoiceDetails.postalCode}
                                    </p>
                                 </div>
                              </div>
                              <div className="col-md-4">
                                 <div className={classes.invoiceCard}>
                                    <p className={classes.invoiceDate}>
                                       Invoice Date
                                    </p>
                                    <span className={classes.invoiceDateVal}>
                                       {invoiceDetails.invoiceDate}
                                    </span>
                                 </div>
                                 <div className={classes.invoiceCard}>
                                    <p className={classes.invoiceNumber}>
                                       Invoice Number
                                    </p>
                                    <span className={classes.invoiceNumberVal}>
                                       {invoiceDetails.invoiceNumber}
                                    </span>
                                 </div>
                                 <div className={classes.invoiceCard}>
                                    <p className={classes.invoiceReference}>
                                       Reference
                                    </p>
                                    <span
                                       className={classes.invoiceReferenceVal}
                                    >
                                       {invoiceDetails.reference}
                                    </span>
                                 </div>
                              </div>
                              <div className="col-md-4">
                                 <div>
                                    <p className={classes.invoiceAddress}>
                                       Silk Road Partners Limited
                                    </p>
                                    <p className={classes.invoiceAddress}>
                                       No.176/1, Alwis Avenue
                                    </p>
                                    <p className={classes.invoiceAddress}>
                                       CASTLE STREET COLOMBO 08
                                    </p>
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-md-12 mt-5">
                                 <table
                                    className={`${classes.invoiceTable} table`}
                                    id="#example"
                                 >
                                    <thead>
                                       <tr>
                                          <th scope="col">Description</th>
                                          <th
                                             scope="col"
                                             className="text-right"
                                          >
                                             Quantity
                                          </th>
                                          <th
                                             scope="col"
                                             className="text-right"
                                          >
                                             Unit Price
                                          </th>
                                          <th scope="col">Tax</th>
                                          <th
                                             scope="col"
                                             className="text-right"
                                          >
                                             Amount
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {invoiceDetails.lineItems &&
                                          renderLineItems(
                                             invoiceDetails.lineItems,
                                          )}
                                    </tbody>
                                    <tfoot>
                                       <tr>
                                          <td
                                             colSpan="4"
                                             className="text-right"
                                          >
                                             Subtotal
                                          </td>
                                          <td className="text-right">
                                             {`${
                                                invoiceDetails.currency ===
                                                'USD'
                                                   ? '$'
                                                   : invoiceDetails.currency
                                             } ${invoiceDetails.subTotal}`}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td
                                             className={`${classes.tableTopBorder} text-right`}
                                          >
                                             {' Tax '}
                                          </td>
                                          <td
                                             className={`${classes.tableTopBorder} text-right`}
                                          >
                                             {invoiceDetails.totalTax}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td
                                             className={`${classes.tableTopBorder} text-right`}
                                          >
                                             {' Invoice Total '}
                                          </td>
                                          <td
                                             className={`${classes.tableTopBorder} text-right`}
                                          >
                                             {invoiceDetails.Total}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td
                                             colSpan="4"
                                             className="text-right"
                                          >
                                             {' Total Net Payments'}
                                          </td>
                                          <td className="text-right">
                                             {invoiceDetails.paid}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td
                                             className={`${classes.tableTopBorder} text-right`}
                                          >
                                             <b>Amount Due </b>
                                          </td>
                                          <td
                                             className={`${classes.tableTopBorder} text-right`}
                                          >
                                             <b>{invoiceDetails.due}</b>
                                          </td>
                                       </tr>
                                    </tfoot>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {loading && (
            <div className={classes.loadingComponent}>
               <div className={classes.loader}>
                  <SpinnerDiamond
                     className={classes.loaderSpin}
                     size={90}
                     thickness={100}
                     speed={71}
                     color="rgba(0,0,0,1)"
                     secondaryColor="rgba(0, 0, 0, 0.3)"
                  />

                  <div className={classes.loadingDescription}>
                     <Typewriter
                        options={{
                           strings: ['Loading ....', 'Please wait...'],
                           autoStart: true,
                           loop: true,
                           textAlign: 'center',
                        }}
                     />
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default InvoiceDetail;
