//import classes from './Home.module.css';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SpinnerDiamond } from 'spinners-react';
import Typewriter from 'typewriter-effect';
import Card from '../../components/Card/Card';
import TopNav from '../../components/TopNav/TopNav';
import { googleDriveThunk } from '../../state/thunks/GoogleDriveThunk';
import { InvoiceFetchThunk } from '../../state/thunks/InvoicesFetchThunk';
import AmexLogo from './../../assets/images/amex.svg';
import InvoicesBackground from './../../assets/images/invoicesBackground.svg';
import MasterLogo from './../../assets/images/mastercard.svg';
import PaymentHistoryBackground from './../../assets/images/paymentHistoryBackground.svg';
import VisaLogo from './../../assets/images/visa.svg';
import classes from './Home.module.css';

const Home = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const data = useSelector((state) => {
      return state.data;
   });
   const clientId = useSelector((state) => {
      return state.clientId;
   });

   const isLoggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });

   const driveId = useSelector((state) => {
      return state.driveId;
   });

   const outstanding = useSelector((state) => {
      return state.outstanding;
   });
   const currency = useSelector((state) => {
      return state.currency;
   });

   const invoiceLoading = useSelector((state) => {
      return state.invoiceLoading;
   });

   const paymentsLoading = useSelector((state) => {
      return state.paymentsLoading;
   });

   const loading = paymentsLoading || invoiceLoading;

   useEffect(() => {
      if (!isLoggedIn) {
         navigate('/');
      }

      if (isLoggedIn) {
         if (!Array.isArray(data) && driveId) {
            dispatch(googleDriveThunk({ folderId: driveId }));
         }
         // fetch total outstanding
         //fetch invoices
         if (clientId) {
            dispatch(InvoiceFetchThunk({ contactId: clientId }));
         }
         //fetch payments
         // if (payments.length === 0) {
         // dispatch(PaymentFetchThunk({ contactId: clientId }));
         // }
      }
   }, [clientId]);

   return (
      <>
         <div className={classes.home}>
            <TopNav />
            <div className={classes.scroller}>
               <div className="row mx-0 p-0">
                  <div className="col-lg-4 m-0 p-0">
                     <div>
                        <Card>
                           <div>
                              <p className={classes.cardHeading}>Invoices</p>
                              <p className={classes.cardDescription}>
                                 Checkout your recent invoices
                              </p>
                           </div>
                           <div className={classes.detailsButtonWrapper}>
                              <Link
                                 to="/invoices"
                                 className={classes.payNowLink}
                              >
                                 <button className={classes.paynow}>
                                    {' DETAILS'}
                                    <FeatherIcon
                                       icon="chevrons-right"
                                       size={20}
                                    />
                                 </button>
                              </Link>
                           </div>
                           <img
                              src={PaymentHistoryBackground}
                              alt="Payment"
                              className={classes.backgroundImagePaymentHistory}
                           />
                           <img
                              src={PaymentHistoryBackground}
                              alt="Payment"
                              className={classes.backgroundImagePaymentHistory2}
                           />
                        </Card>
                     </div>
                     <div>
                        <Card>
                           <div>
                              <p className={classes.cardHeading}>
                                 Contact List
                              </p>
                              <p className={classes.cardDescription}>
                                 Contact Silkroad partners for more
                                 information..
                              </p>
                           </div>
                           <div className={classes.detailsButtonWrapper}>
                              <Link
                                 to="/contact-us"
                                 className={classes.payNowLink}
                              >
                                 <button className={classes.paynow}>
                                    <p>DETAILS</p>{' '}
                                    <FeatherIcon
                                       icon="chevrons-right"
                                       size={20}
                                    />
                                 </button>
                              </Link>
                           </div>
                           <img
                              src={PaymentHistoryBackground}
                              alt="Payment"
                              className={classes.backgroundImagePaymentHistory}
                           />
                           <img
                              src={PaymentHistoryBackground}
                              alt="Payment"
                              className={classes.backgroundImagePaymentHistory2}
                           />
                        </Card>
                     </div>
                  </div>
                  <div className="col-lg-4 m-0 p-0">
                     <div>
                        <Card>
                           <div>
                              <p className={classes.cardHeading}>Folders</p>
                              <p className={classes.cardDescription}>
                                 Checkout your files and documentation from here
                              </p>
                           </div>
                           <div className={classes.detailsButtonWrapper}>
                              <Link to="/docs">
                                 <button className={classes.paynow}>
                                    {'DETAILS '}
                                    <FeatherIcon
                                       icon="chevrons-right"
                                       size={20}
                                    />
                                 </button>
                              </Link>
                           </div>
                           <img
                              src={InvoicesBackground}
                              alt="Folders"
                              className={classes.backgroundImageInvoiceHistory}
                           />
                           <img
                              src={PaymentHistoryBackground}
                              alt="Folders"
                              className={classes.backgroundImageInvoiceHistory2}
                           />
                        </Card>
                     </div>
                  </div>
                  <div className="col-lg-4 m-0 p-0">
                     <div className={classes.outStandingContainer}>
                        <div className="row">
                           <div className="col-md-12">
                              <p className={classes.outStandingHeading}>
                                 Total Outstanding
                              </p>
                              <hr className={classes.outStandingHr}></hr>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <p className={classes.amount}>
                                 {`${currency} ${parseFloat(
                                    outstanding,
                                 ).toFixed(2)}`}
                              </p>
                           </div>
                           <div className="col-lg-6 col-md-6 col-sm-6 col-12 ">
                              <p className={classes.cardDate}>
                                 to {`${new Date().toString().slice(0, 15)}`}
                              </p>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-12">
                              <Link to="/outstandingPayments">
                                 <button className={classes.paynow1}>
                                    PAY NOW
                                    <FeatherIcon
                                       icon="credit-card"
                                       size={20}
                                       className={classes.creaditCard}
                                    />
                                 </button>{' '}
                              </Link>
                           </div>
                        </div>
                        <hr className={classes.outStandingHr}></hr>
                        <div className="row">
                           <div className="col-md-12">
                              <img
                                 src={VisaLogo}
                                 alt="visa logo"
                                 className={classes.visaLogo}
                              />
                              <img
                                 src={MasterLogo}
                                 alt="visa logo"
                                 className={classes.visaLogo}
                              />
                              <img
                                 src={AmexLogo}
                                 alt="visa logo"
                                 className={classes.visaLogo}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row mx-0 p-0">
                  <div className="col-lg-4 m-0 p-0"></div>
               </div>
            </div>
         </div>
         {loading && (
            <div className={classes.loadingComponent}>
               <div className={classes.loader}>
                  <SpinnerDiamond
                     className={classes.loaderSpin}
                     size={90}
                     thickness={100}
                     speed={71}
                     color="rgba(0,0,0,1)"
                     secondaryColor="rgba(0, 0, 0, 0.3)"
                  />

                  <div className={classes.loadingDescription}>
                     <Typewriter
                        options={{
                           strings: [
                              'Please wait....',
                              'You are almost there ...',
                           ],
                           autoStart: true,
                           loop: true,
                           textAlign: 'center',
                        }}
                     />
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default Home;
