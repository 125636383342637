import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './PaymentDetail.module.css';

export const PaymentResponseError = () => {
   return (
      <div className="row ">
         <div className="mt-5 align-items-center">
            <div className={`d-flex justify-content-center `}>
               <div className="col-md-6 ">
                  <div className={classes.paymentError}>
                     <div className={classes.payment_header}>
                        <div className={classes.close}>
                           <FeatherIcon
                              icon="x"
                              size={30}
                              className={classes.checkicon}
                           />
                        </div>
                     </div>
                     <div className={classes.content}>
                        <h1>Payment Failed</h1>
                        <div>
                           <p>
                              Payment Declined. Please try again. This can occur
                              on below situations.
                           </p>
                           <ul>
                              <li>Card details are not valid</li>
                              <li>Transaction rejected</li>
                              <li>Network error</li>
                              <li>System error</li>
                           </ul>
                        </div>
                        <Link className={classes.goBackLink} to="/">
                           Go back
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default PaymentResponseError;
