import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import ContactList from './pages/ContactList/ContactList';
import Docs from './pages/Docs/Docs';
import Home from './pages/Home/Home';
import InvoiceDetail from './pages/InvoiceDetails/InvoiceDetail';
import Invoices from './pages/Invoices/Invoices';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import OutstandingPayments from './pages/PaymentDetail/OutstandingPayments';
import PaymentResponseSuccess from './pages/PaymentDetail/PaymentResponse';
import PaymentResponseError from './pages/PaymentResponseError/PaymentResponseError';

require('dotenv').config();

function App() {
   const isSidebarOn = useSelector((status) => {
      return status.isSidebarOn;
   });

   return (
      <>
         <Routes>
            <Route path="/" element={<Login />} />
            <Route
               path="/home"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <Home sideBarStatus={isSidebarOn} />
                  </>
               }
            />
            <Route
               path="/docs"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <Docs sideBarStatus={isSidebarOn} />
                  </>
               }
            />
            <Route
               path="/invoiceDetail/:id"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <InvoiceDetail
                        isInvoices={true}
                        sideBarStatus={isSidebarOn}
                     />
                  </>
               }
            />
            <Route
               path="/invoices"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <Invoices sideBarStatus={isSidebarOn} />
                  </>
               }
            />
            <Route
               path="/outstandingPayments"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <OutstandingPayments sideBarStatus={isSidebarOn} />
                  </>
               }
            />
            <Route
               path="/payment-response-success"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <PaymentResponseSuccess sideBarStatus={isSidebarOn} />
                  </>
               }
            />

            <Route
               path="/payment-response-error"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <PaymentResponseError sideBarStatus={isSidebarOn} />
                  </>
               }
            />
            <Route
               path="/outstandingPayments/invoiceDetail/:id"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <InvoiceDetail
                        isInvoices={false}
                        sideBarStatus={isSidebarOn}
                     />
                  </>
               }
            />
            <Route
               path="/contact-us"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <ContactList sideBarStatus={isSidebarOn} />
                  </>
               }
            />
            <Route
               path="*"
               element={
                  <>
                     <Sidebar sideBarStatus={isSidebarOn} />
                     <NotFound sideBarStatus={isSidebarOn} />
                  </>
               }
            />
         </Routes>
         {/* <OverlayModel isOpen={isSidebarOn} /> */}
      </>
   );
}

export default App;
