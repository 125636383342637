import { configureStore } from '@reduxjs/toolkit';
import { systemSlice } from './slices/AppStatusSlice';

// import { ThunkAction } from 'redux-thunk';

const store = configureStore({
   reducer: systemSlice.reducer,
   devTools: true,
   //    middleware: applyMiddleware(ThunkMiddleware),
});

export const systemActions = systemSlice.actions;
export default store;
