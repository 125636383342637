/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import TopNav from '../../components/TopNav/TopNav';
import classes from './PaymentDetail.module.css';
export const outstandingPayments = (props) => {
   const navigate = useNavigate();
   const isLoggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });

   const [agreement, SetAgreement] = useState(false);
   const [totalAmount, setTotalAmount] = useState(0);
   const [invoice, setInvoice] = useState({ ids: [] });
   const [showAgreement, setShowAgreement] = useState(false);
   const agreementRef = useRef();

   const upInvoices = useSelector((state) => {
      return state.invoices;
   });

   const currency = useSelector((state) => {
      return state.currency;
   });
   const clientId = useSelector((state) => {
      return state.clientId;
   });

   useEffect(() => {
      if (isLoggedIn) {
         if (upInvoices.length > 0) {
            calculateTotal(upInvoices);
         }
      } else if (!isLoggedIn) {
         navigate('/');
      }
   }, []);

   const calculateTotal = (array) => {
      var amountTotal = 0;
      array.forEach((item) => {
         if (item.Status === 'AUTHORISED' && item.SentToContact) {
            if (item.AmountDue) {
               amountTotal += item.AmountDue;
            }
         }
      });
      setTotalAmount(amountTotal);
      setInvoices(array);
   };

   const setInvoices = (array) => {
      let ids = invoice;
      array.forEach((item) => {
         if (item.Status === 'AUTHORISED' && item.SentToContact) {
            ids.ids.push(item.InvoiceID);
         }
      });

      setInvoice(ids);
   };

   const renderInvoices = (array) => {
      let invoicesTemp = [];

      array.forEach((item) => {
         if (item.Status === 'AUTHORISED' && item.SentToContact) {
            let invoice = (
               <tr key={item.InvoiceID}>
                  <td>
                     <input
                        type="checkbox"
                        defaultChecked={true}
                        onClick={(e) => {
                           if (e.target.checked === true) {
                              clickHandler(true, item.AmountDue);
                              invoiceIdHandler(true, item.InvoiceID);
                           } else if (e.target.checked === false) {
                              clickHandler(false, item.AmountDue);
                              invoiceIdHandler(false, item.InvoiceID);
                           }
                        }}
                     />
                  </td>
                  <td>{item.InvoiceNumber}</td>
                  <td>{item.DateString.slice(0, 10)}</td>
                  <td>{item.DueDateString.slice(0, 10)}</td>
                  <td>{`${item.AmountDue} ${item.CurrencyCode}`}</td>
                  <td>
                     <Link
                        to={`/outstandingPayments/invoiceDetail/${item.InvoiceID}`}
                     >
                        <FeatherIcon icon="eye" size={20} />
                     </Link>
                  </td>
               </tr>
            );

            invoicesTemp.push(invoice);
         }
      });

      return invoicesTemp;
   };

   const invoiceIdHandler = (status, invoiceId) => {
      let ids = invoice;

      if (status === true) {
         ids.ids.push(invoiceId);
      } else if (status === false) {
         ids.ids = ids.ids.filter((item) => {
            return item !== invoiceId;
         });

         // if (Array.isArray(ids)) {
         //    ids = {
         //       ids: [],
         //    };
         // }
      }

      setInvoice(ids);
   };

   const clickHandler = (status, amountInvoice) => {
      let price;
      if (status) {
         price = totalAmount + amountInvoice;
      } else if (!status) {
         price = totalAmount - amountInvoice;
      }
      setTotalAmount(price);
   };

   $('#example').DataTable({
      destroy: true,
      searching: false,
      order: [[2, 'asc']],
   });

   function buildPayment() {
      let amount = totalAmount * 100;
      let lkr = 14005263;
      let usd = 14005264;
      let aud = 14005291;
      let eur = 14005293;
      let gbp = 14005294;
      let sgd = 14005295;

      let currencyNew = 0;

      switch (currency) {
         case 'LKR':
            currencyNew = lkr;
            break;
         case 'USD':
            currencyNew = usd;
            break;
         case 'AUD':
            currencyNew = aud;
            break;
         case 'EUR':
            currencyNew = eur;
            break;
         case 'GBP':
            currencyNew = gbp;
            break;
         case 'SGD':
            currencyNew = sgd;
            break;
         default:
            break;
      }

      return {
         clientId: currencyNew,
         paymentAmount: amount,
         currency: currency,
         returnUrl: `${process.env.REACT_APP_BACKEND_URL}/api/payment/hostedPayment`,
         clientRef: `${clientId}`,
         tokenize: true,
         comment: JSON.stringify(invoice),
      };
   }

   const classButton = agreement ? 'btn btn-dark' : 'btn btn-secondary';

   const { sideBarStatus } = props;
   const classBlur = sideBarStatus ? `${classes.blur}` : `${classes.none}`;
   return (
      <>
         <div className={classes.home}>
            <div className={classes.docPage}>
               <TopNav />
               <div>
                  <div className={classes.folderContainer}>
                     <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                 <Link
                                    to="/home"
                                    className={classes.breadcrumbText}
                                 >
                                    {'Home'}
                                 </Link>
                              </li>

                              <li
                                 className="breadcrumb-item active"
                                 aria-current="page"
                              >
                                 Outstanding Payments
                              </li>
                           </ol>
                        </nav>
                        <button
                           className="btn btn-dark my-3"
                           onClick={() => {
                              navigate(-1);
                           }}
                        >
                           Go Back
                        </button>{' '}
                     </div>

                     <div className="row mb-4">
                        <div
                           className={`${classes.topdiv} d-flex justify-content-center`}
                        >
                           <p
                              className={`${classes.showAmount} mb-1 w-100 text-center`}
                           >
                              <b>
                                 <span className={classes.amountText}>
                                    Total Amount :
                                 </span>
                                 {`${totalAmount.toFixed(2)} ${currency}`}
                              </b>
                           </p>
                           <p className="mb-1 w-100 text-center">
                              <div className="form-check">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={false}
                                    id="flexCheckChecked"
                                    ref={agreementRef}
                                    onClick={() => {
                                       SetAgreement(!agreement);
                                    }}
                                 ></input>
                                 <label
                                    className={`${classes.label1} form-check-label`}
                                    htmlFor="flexCheckChecked"
                                 >
                                    I agreed to{' '}
                                    <Link
                                       href=""
                                       onClick={() => {
                                          setShowAgreement(true);
                                       }}
                                       className={classes.termsandConditions}
                                    >
                                       Terms & Conditions
                                    </Link>
                                 </label>
                              </div>
                           </p>
                           <p className="mb-1 w-100 text-center pt-3">
                              <button
                                 type="button"
                                 className={classButton}
                                 onClick={() => {
                                    if (agreementRef.current.checked) {
                                       if (totalAmount > 0) {
                                          loadPaycorpPayment(buildPayment());
                                       }
                                    }
                                 }}
                              >
                                 PAY NOW
                              </button>
                           </p>
                        </div>
                        <div className="d-flex justify-content-center"></div>
                     </div>

                     <div className={classes.fileStructurePreview}>
                        <div>
                           <div className="col-md-12">
                              <div className={classes.invoicegrid}>
                                 <div className="table-responsive">
                                    <table
                                       id="example"
                                       className="table table-striped tb"
                                    >
                                       <thead
                                          className={classes.invoiceTablehead}
                                       >
                                          <tr>
                                             <th></th>
                                             <th>Invoice ID</th>
                                             <th>Date</th>
                                             <th>Due Date</th>
                                             <th>Amount</th>
                                             <th></th>
                                          </tr>
                                       </thead>
                                       <tbody className={classes.invoiceTBody}>
                                          {renderInvoices(upInvoices)}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {showAgreement && (
            <div
               className={classes.loadingComponent}
               onClick={() => {
                  setShowAgreement(false);
               }}
            >
               <div className={classes.policyContainer}>
                  <h1>Payment Terms and Refund Policy</h1>
                  <ul>
                     <li>
                        Prices are quoted in GBP, USD, EURO,SGD,USD and LKR
                        depending on your preference and you must pay in the
                        currency displayed in the invoice.
                     </li>
                     <li>
                        Amount once paid through the payment gateway shall not
                        be refunded other than in the following circumstance Due
                        to a technical error, the customer's card/bank account
                        was debited multiple times or the customer's account was
                        debited with an excessive amount in a single
                        transaction. In such cases, the excess amount, excluding
                        Payment Gateway charges, would be refunded to the
                        Customer.
                     </li>
                     <li>
                        The next invoice will be issued based on your plan.
                        (monthly, quarterly, or semiannually)
                     </li>
                  </ul>
                  <div
                     className={classes.buttonGoBack}
                     onClick={() => {
                        setShowAgreement(false);
                     }}
                  >
                     Go Back
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default outstandingPayments;
