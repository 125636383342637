import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const PaymentFetchThunk = createAsyncThunk(
   'AppState/PaymentFetchThunk',
   async (args, { rejectWithValue }) => {
      try {
         const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/xero/get-payments`,
            {
               clientId: args.contactId,
            },
         );
         return data;
      } catch (error) {
         rejectWithValue(error.response.data);
      }
   },
);
