import { menuItem } from '../state/InitialState';

export const sidebarTopConstants = [
   {
      label: 'Home',
      icon: 'home',
      tag: menuItem.home,
      path: '/home',
   },
   {
      label: 'My Docs',
      icon: 'folder',
      tag: menuItem.docs,
      path: '/docs',
   },

   // {
   //    label: 'Reports',
   //    icon: 'pie-chart',
   //    tag: menuItem.reports,
   //    path: '/reports',
   // },

   // {
   //    label: 'Payments',
   //    icon: 'credit-card',
   //    tag: menuItem.payments,
   //    path: '/payments',
   // },

   {
      label: 'Invoices',
      icon: 'file',
      tag: menuItem.invoices,
      path: '/invoices',
   },

   // {
   //    label: 'Blogs',
   //    icon: 'message-circle',
   //    tag: menuItem.blogs,
   //    path: '/blog',
   // },
];

export const sidebarBottomConstants = [
   {
      label: 'FAQ',
      icon: 'help-circle',
      tag: menuItem.faq,
      path: '/faq',
   },
   // {
   //    label: 'Other Services',
   //    icon: 'sliders',
   //    tag: menuItem.otherServices,
   //    path: '/other-services',
   // },
   {
      label: 'Contact Us',
      icon: 'phone-call',
      tag: menuItem.contactUs,
      path: '/contact-us',
   },
];
