/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SpinnerDiamond } from 'spinners-react';
import Typewriter from 'typewriter-effect';
import { systemActions } from '../../state';
import LOCKSVG from './../../assets/images/lock.svg';

import classes from './Login.module.css';
axios.defaults.withCredentials = true;
const Login = () => {
   const navigate = useNavigate();
   const [showPassword, setShowPassword] = useState(false);
   const [errorText, setErrorText] = useState(false);
   const usernameRef = useRef();
   const passwordRef = useRef();
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);

   const showPasswordHandler = () => {
      setShowPassword(!showPassword);
   };

   useEffect(() => {
      checkToken();
   }, []);

   const checkToken = async () => {
      try {
         setLoading(true);
         const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/auth/check-user`,
         );
         setLoading(false);

         if (response.data.status && response.data.status === 'success') {
            if (response.data.companies) {
               dispatch(
                  systemActions.setClients({
                     clients: response.data.companies,
                     clientId: response.data.companies[0].contactId,
                     selectedCompany: response.data.companies[0].companyName,
                  }),
               );
            }
            if (response.data.driveId) {
               dispatch(
                  systemActions.addDriveId({
                     driveId: response.data.driveId,
                  }),
               );
            }

            dispatch(
               systemActions.loggedIn({
                  status: true,
               }),
            );

            navigate('/home');
         }
      } catch (err) {
         console.log(err);
         setLoading(false);
      }
   };

   const loginHandler = async (event) => {
      event.preventDefault();

      let email = usernameRef.current.value;
      let password = passwordRef.current.value;

      try {
         setLoading(true);
         if (email.trim() && password.trim()) {
            const response = await axios.post(
               `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`,
               {
                  email,
                  password,
               },
            );

            if (response.data) {
               if (response.data.status === 'success') {
                  if (response.data.companies) {
                     dispatch(
                        systemActions.setClients({
                           clients: response.data.companies,
                           clientId: response.data.companies[0].contactId,
                           selectedCompany:
                              response.data.companies[0].companyName,
                        }),
                     );
                  }
                  if (response.data.driveId) {
                     dispatch(
                        systemActions.addDriveId({
                           driveId: response.data.driveId,
                        }),
                     );
                  }

                  dispatch(
                     systemActions.loggedIn({
                        status: true,
                     }),
                  );
                  setErrorText(false);
                  navigate('/home');
               } else if (
                  response.data.status === 'error' ||
                  response.data.status === 'Fail'
               ) {
                  setErrorText(true);
               }
            }
         } else {
            setLoading(false);
            setErrorText(true);
         }
      } catch (err) {
         setLoading(false);
         setErrorText(true);
      }
   };

   return (
      <>
         <div className={classes.loginPage}>
            <form className={classes.loginContainer} onSubmit={loginHandler}>
               <div className={classes.header}>
                  <h1>SILK ROAD</h1>
                  <p>Client Dashboard</p>
               </div>
               <div className={classes.lockIconWrapper}>
                  <img
                     src={LOCKSVG}
                     className={classes.lockIcon}
                     alt="log icon"
                  />
                  <p className={classes.iconCaption}>USER LOGIN</p>
               </div>
               <div className={classes.inputSection}>
                  <input type="text" ref={usernameRef} placeholder="Username" />
                  <input
                     type="password"
                     className={classes.passwordInput}
                     ref={passwordRef}
                     placeholder="Password"
                  />
               </div>
               {errorText && (
                  <div className={classes.errorText}>
                     ***Credentials are incorrect. Please try again.
                  </div>
               )}
               <div className={classes.utilityContainer}>
                  <label className={classes.rememberMe}>
                     <input
                        type="checkbox"
                        className={classes.checkBox}
                        defaultChecked={showPassword}
                        onChange={showPasswordHandler}
                     />
                     Remember me
                  </label>
                  <div className={classes.forgotPasswordDiv}>
                     <Link>Forgot password</Link>
                  </div>
               </div>
               <div className={classes.buttonContainer}>
                  <button className={classes.submitButton} type="submit">
                     LOGIN
                  </button>
               </div>

               <div className={classes.horizontalLine}></div>
               <p className={classes.description}>
                  Don&apos;t have an account? Please contact us.
               </p>
            </form>
         </div>

         {loading && (
            <div className={classes.loadingComponent}>
               <div className={classes.loader}>
                  <SpinnerDiamond
                     className={classes.loaderSpin}
                     size={90}
                     thickness={100}
                     speed={71}
                     color="rgba(0,0,0,1)"
                     secondaryColor="rgba(0, 0, 0, 0.3)"
                  />

                  <div className={classes.loadingDescription}>
                     <Typewriter
                        options={{
                           strings: ['Loading ....', 'Please wait...'],
                           autoStart: true,
                           loop: true,
                           textAlign: 'center',
                        }}
                     />
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default Login;
