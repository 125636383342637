import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../InitialState';
import { googleDriveThunk } from '../thunks/GoogleDriveThunk';
import { InvoiceFetchThunk } from '../thunks/InvoicesFetchThunk';
import { PaymentFetchThunk } from '../thunks/PaymentFetchThunk';

export const systemSlice = createSlice({
   name: 'AppState',
   initialState: initialState,
   reducers: {
      menubarActive(state, action) {
         return { ...state, sidebarActive: action.payload.sidebarActive };
      },
      menubarOpen(state, action) {
         return { ...state, isSidebarOn: action.payload.isSidebarOn };
      },

      updatedPath(state, action) {
         return {
            ...state,
            updatedFileStructure: [
               ...state.updatedFileStructure,
               action.payload.updatedFileStructure,
            ],
         };
      },

      backButtonPath(state, action) {
         let array = [...state.updatedFileStructure];
         let arrayFiles = [...state.files];
         let breadCrumb = [...state.breadcrumb];
         breadCrumb.pop();
         array.pop();
         arrayFiles.pop();
         return {
            ...state,
            updatedFileStructure: array,
            files: arrayFiles,
            breadcrumb: breadCrumb,
         };
      },
      updateBreadCrumb(state, action) {
         if (action.payload.option === 'push') {
            return {
               ...state,
               breadcrumb: [...state.breadcrumb, action.payload.folderName],
            };
         }
      },
      updateFiles(state, action) {
         return {
            ...state,
            files: [...state.files, action.payload.files],
         };
      },
      destroyBreadCrumb(state, action) {
         return {
            ...state,
            breadcrumb: [],
         };
      },
      addDriveId(state, action) {
         return {
            ...state,
            driveId: action.payload.driveId,
         };
      },
      loggedIn(state, action) {
         if (action.payload.status === false) {
            return {
               ...initialState,
            };
         }
         return {
            ...state,
            isLoggedIn: action.payload.status,
         };
      },
      setClients(state, action) {
         return {
            ...state,
            clients: action.payload.clients,
            clientId: action.payload.clientId,
            selectedCompany: action.payload.selectedCompany,
         };
      },

      selectCompany(state, action) {
         return {
            ...state,
            clientId: action.payload.clientId,
            selectedCompany: action.payload.selectedCompany,
         };
      },
   },
   extraReducers: {
      [googleDriveThunk.pending]: (state, { payload }) => {
         state.driveLoading = true;
         return state;
      },
      [googleDriveThunk.fulfilled]: (state, { payload }) => {
         state.driveLoading = false;
         if (payload && payload.folderTree) {
            state.data = payload.folderTree;
         }
         state.updatedFileStructure = payload.folderTree;
         return state;
      },
      [googleDriveThunk.rejected]: (state, { payload }) => {
         state.driveLoading = false;
         return state;
      },
      [InvoiceFetchThunk.pending]: (state, { payload }) => {
         state.invoiceLoading = true;
         return state;
      },
      [InvoiceFetchThunk.fulfilled]: (state, { payload }) => {
         if (payload && payload.invoices) {
            state.invoices = payload.invoices;
         }

         let outstanding = 0;
         let currency = '';

         if (payload && payload.invoices.length > 0 && payload.invoices[0]) {
            currency = payload.invoices[0].CurrencyCode;
            payload.invoices.forEach((item) => {
               if (item.Status === 'AUTHORISED' && item.SentToContact) {
                  outstanding += item.AmountDue;
               }
            });
         }
         state.outstanding = outstanding;
         state.currency = currency;
         state.invoiceLoading = false;
         return state;
      },
      [InvoiceFetchThunk.rejected]: (state, { payload }) => {
         state.invoices = [];
         state.invoiceLoading = false;
         return state;
      },
      [PaymentFetchThunk.pending]: (state, { payload }) => {
         state.paymentsLoading = true;
         return state;
      },
      [PaymentFetchThunk.fulfilled]: (state, { payload }) => {
         if (payload && payload.payments) {
            state.payments = payload.payments;
         }
         state.paymentsLoading = false;
         return state;
      },
      [PaymentFetchThunk.rejected]: (state, { payload }) => {
         state.paymentsLoading = false;
         return state;
      },
   },
});
