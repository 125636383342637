import FeatherIcon from 'feather-icons-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SpinnerDiamond } from 'spinners-react';
import Typewriter from 'typewriter-effect';
import TopNav from '../../components/TopNav/TopNav';
import classes from './Invoices.module.css';
// import $ from 'jquery';
// import DataTable from 'react-data-table-component';

export const Invoice = () => {
   const upInvoices = useSelector((state) => {
      return state.invoices;
   });

   const loading = useSelector((state) => {
      return state.invoiceLoading;
   });

   const navigate = useNavigate();
   const isLoggedIn = useSelector((state) => {
      return state.isLoggedIn;
   });

   useEffect(() => {
      if (!isLoggedIn) {
         navigate('/');
      }
   }, []);

   // const [invoices, setInvoices] = useState();
   const renderInvoices = (array) => {
      let invoicesTemp = [];
      array.forEach((item) => {
         if (
            (item.Status === 'AUTHORISED' && item.SentToContact) ||
            (item.Status === 'PAID' && item.SentToContact)
         ) {
            const amount = `${item.CurrencyCode} ${item.Total}`;
            let badge;

            // if (item.Status === 'DRAFT') {
            // } else if (item.Status === 'VOIDED') {
            // else if (item.Status === 'DELETED') {}
            if (item.Status === 'AUTHORISED' && item.SentToContact) {
               badge = (
                  <span className="badge bg-danger text-center">
                     {' PENDING'}
                  </span>
               );
            } else if (item.Status === 'PAID' && item.SentToContact) {
               badge = <span className="badge bg-success">{'PAID '}</span>;
            }
            let invoice = (
               <tr>
                  <td></td>
                  <td>{item.InvoiceNumber}</td>
                  <td>{item.DateString.slice(0, 10)}</td>
                  <td>{amount}</td>

                  <td>{badge}</td>
                  <td>
                     <Link to={`/InvoiceDetail/${item.InvoiceID}`}>
                        <FeatherIcon icon="eye" size={20} />
                     </Link>
                  </td>
               </tr>
            );
            invoicesTemp.push(invoice);
         }
      });
      return invoicesTemp;
   };

   return (
      <>
         <div className={classes.home}>
            <div className={classes.docPage}>
               <TopNav />
               <div>
                  <div className={classes.folderContainer}>
                     <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                 <Link
                                    to="/home"
                                    className={classes.breadcrumbText}
                                 >
                                    {'Home'}
                                 </Link>
                              </li>

                              <li
                                 className="breadcrumb-item active"
                                 aria-current="page"
                              >
                                 Invoices
                              </li>
                           </ol>
                        </nav>
                        <button
                           className="btn btn-dark my-3"
                           onClick={() => {
                              navigate(-1);
                           }}
                        >
                           Go Back
                        </button>{' '}
                     </div>
                     <div className={classes.fileStructurePreview}>
                        <div>
                           <div className="col-md-12">
                              <div className={classes.invoicegrid}>
                                 <div className="  table-responsive">
                                    <table
                                       id="invoice"
                                       className="  table table-striped invoice"
                                    >
                                       <thead
                                          className={classes.invoiceTablehead}
                                       >
                                          <tr>
                                             <th></th>
                                             <th>Invoice ID</th>
                                             <th>Invoiced Date</th>
                                             {/* <th>Due Date</th> */}
                                             <th>Amount</th>
                                             <th>Status</th>
                                             <th></th>
                                          </tr>
                                       </thead>
                                       <tbody className={classes.invoiceTBody}>
                                          {renderInvoices(upInvoices)}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {loading && (
            <div className={classes.loadingComponent}>
               <div className={classes.loader}>
                  <SpinnerDiamond
                     className={classes.loaderSpin}
                     size={90}
                     thickness={100}
                     speed={71}
                     color="rgba(0,0,0,1)"
                     secondaryColor="rgba(0, 0, 0, 0.3)"
                  />

                  <div className={classes.loadingDescription}>
                     <Typewriter
                        options={{
                           strings: ['Loading ....', 'Please wait...'],
                           autoStart: true,
                           loop: true,
                           textAlign: 'center',
                        }}
                     />
                  </div>
               </div>
            </div>
         )}
      </>
   );
   //<script>
   // $(document).ready(function () {
   //    $('#invoice').DataTable();
   // });
   //</script>
};

export default Invoice;
