import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const googleDriveThunk = createAsyncThunk(
   'AppState/GoogleDriveThunk',
   async (args, { rejectWithValue }) => {
      try {
         const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/google`,
            {
               folderId: args.folderId,
            },
         );
         return data;
      } catch (error) {
         rejectWithValue(error.response.data);
      }
   },
);
